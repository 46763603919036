import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { UserNotificationPaneContext } from '../../../../appContexts/UserNotificationPaneContext';
import { LocalFileContext } from './_contexts/LocalFileContext';
// import { gectConfig } from './_helpers/grids/gridConfigs';
import { FileSelectorFC } from './_helpers/FileSelectorFC';
import { SectionTitleFC } from '../_helpers/SectionTitleFC';

// NOTES: (\r\n|\r|\n) ACCOUNTS FOR ALL OS
// Linux = \n
// Windows = \r\n
// Macs = \r

export const _LocalFileFC = () => {
   // const { setGlobalMessagePane } = useContext(UserNotificationPaneContext);
   // const { parsedLogfile, setParsedLogfile, fakeApiWithError } =
   //    useContext(LocalFileContext);
   // useEffect(() => {
   //    fakeApiWithError(setGlobalMessagePane, setParsedLogfile);
   // }, []);
   // return <LocalFileSC>{`_LocalFileFC: ${parsedLogfile}`}</LocalFileSC>;

   // const { setGlobalMessagePane } = useContext(UserNotificationPaneContext);
   const { parsedLogfile } = useContext(LocalFileContext);

   // TWO GRID WIDTHS NEEDED
   // const [gridContentWidth, setGridContentWidth] = useState();
   // const [gridWrapperWidth, setGridWrapperWidth] = useState();
   // GRID CONTENT - USED AS REFERENCE
   // GRID BODY - BASE OFF window.screen.width / VW

   const tableWidthRef = useRef();
   if (window.screen.width <= 768) {
      tableWidthRef.current = window.screen.width * 0.95;
   } else {
      tableWidthRef.current = window.screen.width * 0.8 * 0.95;
   }
   // const [columnWidths, setColumnWidths] = useState([
   //    tableWidthRef.current * 0.25,
   //    tableWidthRef.current * 0.25,
   //    tableWidthRef.current * 0.5,
   // ]);
   const [columnWidths, setColumnWidths] = useState([
      tableWidthRef.current * 0.1,
      tableWidthRef.current * 0.1,
      tableWidthRef.current * 0.1,
      tableWidthRef.current * 0.1,
      tableWidthRef.current * 0.1,
      tableWidthRef.current * 0.1,
      tableWidthRef.current * 0.1,
      tableWidthRef.current * 0.1,
      tableWidthRef.current * 0.1,
      tableWidthRef.current * 0.1,
   ]);

   const handleResize = () => {
      // console.log(`Resize`);
      if (window.screen.width <= 768) {
         tableWidthRef.current = window.screen.width * 0.95;
      } else {
         tableWidthRef.current = window.screen.width * 0.8 * 0.95;
      }
      // setColumnWidths([
      //    tableWidthRef.current * 0.25,
      //    tableWidthRef.current * 0.25,
      //    tableWidthRef.current * 0.5,
      // ]);
      setColumnWidths([
         tableWidthRef.current * 0.1,
         tableWidthRef.current * 0.1,
         tableWidthRef.current * 0.1,
         tableWidthRef.current * 0.1,
         tableWidthRef.current * 0.1,
         tableWidthRef.current * 0.1,
         tableWidthRef.current * 0.1,
         tableWidthRef.current * 0.1,
         tableWidthRef.current * 0.1,
         tableWidthRef.current * 0.1,
      ]);
   };

   useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   return (
      <LocalFileSC>
         <SectionTitleFC title={'LOCAL FILE'} />
         <FileSelectorFC />
         {parsedLogfile ? (
            // <NewTable
            //    parsedLogfile={parsedLogfile}
            //    columnWidths={columnWidths}
            //    tableWidthRef={tableWidthRef}
            // />
            <></>
         ) : null}
      </LocalFileSC>
   );
};

const LocalFileSC = styled.div`
   display: flex;
   flex-flow: column nowrap;
   justify-content: flex-start;
   align-items: center;
   width: 100%; // of _PagesSC

   border: solid 1px palevioletred;
`;
